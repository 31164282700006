import { Fragment } from "react";
import { DB } from "../store/store";

const Menu = () => {
    const menuList = DB.headerMenu;

    const clickHandler = (idx) => {
        DB.mobileMenuIdx = idx;
    }

    return (
        <div className="top-navigation">
            <div className="menu-top_nav-container">
                <ul id="menu-top_nav" className="menu">
                    {menuList?.map((a, b) => (
                        <Fragment key={b}>
                            { a.menu_item_parent === '0' ? <li className={`${a.classes[0]} ${(DB.mobileMenuIdx === b ? 'display-menu' : '')}`} onClick={()=>{clickHandler(b)}}>
                                <a className={`sd-link`} 
                                    href={ a.classes[0] === 'has-children' ? void(0) : a.url}
                                >
                                    { a.title }
                                </a>
                                { (a.classes[0] !== "") && <ul className="sub-menu">
                                    {menuList?.map((c, d) => 
                                        (
                                            <Fragment key={d}>
                                                { ''+a.ID === c.menu_item_parent ? 
                                                    <li>
                                                        <a className="sd-link" href={ c.url }>{ c.title }</a>
                                                    </li>
                                                    :
                                                    <></>
                                                }
                                            </Fragment>
                                        )
                                    )}
                                </ul>}
                            </li> : <></>}
                        </Fragment>
                    ))}
                </ul>
            </div>

            <div className="bottom-buttons w-100">
                <div className="w-100 d-flex justify-content-around align-items-center inner-space">
                    <a href={`${process.env.REACT_APP_MAIN_SITE_URL}my-account/`} className="nav-button acc-button w-100">
                        Sign In
                    </a>
                </div>
            </div>

            <div className="d-flex justify-content-around align-items-center social-buttons">
                <div><a target="_blank" href="https://en-gb.facebook.com/williamsjettenders/"><span className="fb"></span></a></div>
                <div><a target="_blank" href="https://twitter.com/williamstenders"><span className="tw"></span></a></div>
                <div><a target="_blank" href="https://www.instagram.com/williamsjettenders/"><span className="ig"></span></a></div>
                <div><a target="_blank" href="https://www.linkedin.com/company/williams-jet-tenders-limited"><span className="lk"></span></a></div>
                <div><a target="_blank" href="https://www.youtube.com/user/WilliamsTenders"><span className="yt"></span></a></div>
		    </div>
        </div>
    )
}

export default Menu