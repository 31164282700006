import { proxy } from "valtio";

const DB = proxy({
    API_ready: false,
    API_ready_for_tender: false,
    
    models:[
        {
            id: 1,
            name: "",
            type: "",
            dimensions: "",
            dimensions_plcholder: "Yachts",
            capacity: "",
            capacity_plcholder: "People",
            config_image: "",
            active: true,
            specifications: [
                {
                    loa: '',
                    beam: '',
                    dry_weight: '',
                    overall_height: '',
                    engine: '',
                    fuel_capacity: '',
                    seating: '',
                    max_speed: '',
                    typical_fitment: '',
                }
            ],
            nodes: [
                {
                    id: 1,
                    node_name: "Engine",
                    active: true,
                    options:[
                        {
                            id: 1,
                            name: "Power Options",
                            desc: "",
                            active: true,
                            child: []
                        },
                    ]
                },

                {
                    id: 2,
                    node_name: "Tubes",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Tube Colour",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 2,
                            name: "Would you like to add your own logo to the tubes?",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "2.2.1",
                                    name: "Yes",
                                    value: "Yes",
                                    active: false,
                                },
                                {
                                    id: 2,
                                    active_id: "2.2.2",
                                    name: "No",
                                    value: "No",
                                    active: false,
                                }
                            ]
                        },
                        {
                            id: 3,
                            name: "Inflation Options",
                            desc: "",
                            active: true,
                            child: []
                        },
                    ]
                },

                {
                    id: 3,
                    node_name: "Upholstery",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Upholstery Colour",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 2,
                            name: "Would you like to add your own logo to the upholstery?",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "3.2.1",
                                    name: "Yes",
                                    value: "Yes",
                                    active: false,
                                },
                                {
                                    id: 2,
                                    active_id: "3.2.2",
                                    name: "No",
                                    value: "No",
                                    active: false,
                                }
                            ]
                        },
                    ]
                },

                {
                    id: 4,
                    node_name: "Gelcoat",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Choose your helm colour",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "4.1.1",
                                    name: "Standard White",
                                    value: "#8f8e88",
                                    active: true,
                                    config_img: "/images/helm/helm_5.webp",
                                },
                                {
                                    id: 2,
                                    active_id: "4.1.2",
                                    name: "Very Light Gray",
                                    value: "#252727",
                                    active: false,
                                    config_img: "/images/helm/helm_2.webp"
                                },
                                {
                                    id: 3,
                                    active_id: "4.1.3",
                                    name: "Gray",
                                    value: "#282b2c",
                                    active: false,
                                    config_img: "/images/helm/helm_3.webp"
                                },
                                {
                                    id: 4,
                                    active_id: "4.1.4",
                                    name: "Big Band",
                                    value: "#303535",
                                    active: false,
                                    config_img: "/images/helm/helm_4.webp"
                                },
                                {
                                    id: 5,
                                    active_id: "4.1.5",
                                    name: "Crust",
                                    value: "#0a0a0a",
                                    active: false,
                                    config_img: "/images/helm/helm_1.webp",                                
                                },
                                {
                                    id: 6,
                                    active_id: "4.1.6",
                                    name: "Trolley grey",
                                    value: "#4f5150",
                                    active: false,
                                    config_img: "/images/helm/helm_6.webp"
                                },
                                {
                                    id: 7,
                                    active_id: "4.1.7",
                                    name: "German Grey",
                                    value: "#606362",
                                    active: false,
                                    config_img: "/images/helm/helm_7.webp"
                                },
                                {
                                    id: 8,
                                    active_id: "4.1.8",
                                    name: "Cynical Black",
                                    value: "#606362",
                                    active: false,
                                    config_img: "/images/helm/helm_8.webp"
                                },
                            ]
                        },
                        {
                            id: 2,
                            name: "Choose your tube pod colour",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "4.2.1",
                                    name: "Standard White",
                                    value: "#9c9b97",
                                    active: true,
                                    config_img: "/images/sideguard/sider_5.webp" 
                                },
                                {
                                    id: 2,
                                    active_id: "4.2.2",
                                    name: "Very Light Gray",
                                    value: "#414343",
                                    active: false,
                                    config_img: "/images/sideguard/sider_2.webp" 
                                },
                                {
                                    id: 3,
                                    active_id: "4.2.3",
                                    name: "Gray",
                                    value: "#474c4c",
                                    active: false,
                                    config_img: "/images/sideguard/sider_3.webp"                                 
                                },
                                {
                                    id: 4,
                                    active_id: "4.2.4",
                                    name: "Big Band",
                                    value: "#4f5253",
                                    active: false,
                                    config_img: "/images/sideguard/sider_4.webp" 
                                },
                                {
                                    id: 5,
                                    active_id: "4.2.5",
                                    name: "Crust",
                                    value: "#1d1d1d",
                                    active: false,
                                    config_img: "/images/sideguard/sider_1.webp" 
                                },
                                {
                                    id: 6,
                                    active_id: "4.2.6",
                                    name: "Trolley grey",
                                    value: "#717372",
                                    active: false,
                                    config_img: "/images/sideguard/sider_6.webp" 
                                },
                                {
                                    id: 7,
                                    active_id: "4.2.7",
                                    name: "German Grey",
                                    value: "#818382",
                                    active: false,
                                    config_img: "/images/sideguard/sider_7.webp" 
                                },
                                {
                                    id: 8,
                                    active_id: "4.2.8",
                                    name: "Cynical Black",
                                    value: "#b1b0aa",
                                    active: false,
                                    config_img: "/images/sideguard/sider_8.webp" 
                                },
                            ]
                        },
                        {
                            id: 3,
                            name: "Choose your deck colour",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "4.3.1",
                                    name: "Standard White",
                                    value: "#797773",
                                    active: true,
                                    config_img: "/images/hull/hull_5.webp"
                                },
                                {
                                    id: 2,
                                    active_id: "4.3.2",
                                    name: "Very Light Gray",
                                    value: "#202322",
                                    active: false,
                                    config_img: "/images/hull/hull_2.webp" 
                                },
                                {
                                    id: 3,
                                    active_id: "4.3.3",
                                    name: "Gray",
                                    value: "#272b2c",
                                    active: false,
                                    config_img: "/images/hull/hull_3.webp"                                 
                                },
                                {
                                    id: 4,
                                    active_id: "4.3.4",
                                    name: "Big Band",
                                    value: "#2e3032",
                                    active: false,
                                    config_img: "/images/hull/hull_4.webp" 
                                },
                                {
                                    id: 5,
                                    active_id: "4.3.5",
                                    name: "Crust",
                                    value: "#0c0c0c",
                                    active: false,
                                    config_img: "/images/hull/hull_1.webp" 
                                },
                                {
                                    id: 6,
                                    active_id: "4.3.6",
                                    name: "Trolley grey",
                                    value: "#494d4c",
                                    active: false,
                                    config_img: "/images/hull/hull_6.webp" 
                                },
                                {
                                    id: 7,
                                    active_id: "4.3.7",
                                    name: "German Grey",
                                    value: "#535856",
                                    active: false,
                                    config_img: "/images/hull/hull_7.webp" 
                                },
                                {
                                    id: 8,
                                    active_id: "4.3.8",
                                    name: "Cynical Black",
                                    value: "#8c8d86",
                                    active: false,
                                    config_img: "/images/hull/hull_8.webp" 
                                },
                            ]
                        },
                        {
                            id: 4,
                            name: "Choose your hull colour",
                            desc: "",
                            active: true,
                            child: []
                        },
                    ]
                },

                {
                    id: 5,
                    node_name: "Deck",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Deck Colour",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "5.1.1",
                                    name: "Standard White",
                                    value: "/images/Deck/deck_1.svg",
                                    active: true,
                                    config_img: "/images/Deck/deck_1.webp" 
                                }
                            ]
                        },
                        // {
                        //     id: 2,
                        //     name: "Caulking Colour",
                        //     desc: "",
                        //     active: true,
                        //     child: [
                        //         {
                        //             id: 1,
                        //             active_id: "5.2.1",
                        //             name: "Standard White",
                        //             value: "/images/Deck/caulking_1.svg",
                        //             active: true,
                        //             config_img: " " 
                        //         }
                        //     ]
                        // },
                    ]
                },

                {
                    id: 6,
                    node_name: "Weather Protection",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Protectors",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "6.1.1",
                                    category: "cat_1",
                                    name: "Bimini",
                                    value: "/images/WeatherProtection/img_1.svg",
                                    active: false,
                                    config_img: "/images/WeatherProtection/bimini.webp",
                                    z_index: 10,
                                }
                            ]
                        },
                    ]
                },

                {
                    id: 7,
                    node_name: "Finishing Touches",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "Fitting / Handling",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 2,
                            name: "Lighting",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 3,
                            name: "Power",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 4,
                            name: "Navigation & Entertainment",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 5,
                            name: "Ties & Straps",
                            desc: "",
                            active: true,
                            child: []
                        },
                        {
                            id: 6,
                            name: "Other Accessories",
                            desc: "",
                            active: true,
                            child: []
                        },
                    ]
                },

                {
                    id: 8,
                    node_name: "Rescue mode",
                    active: true,
                    options: [
                        {
                            id: 1,
                            name: "View SOLAS Rescue Mode",
                            desc: "",
                            active: true,
                            child: [
                                {
                                    id: 1,
                                    active_id: "8.1.1",
                                    name: "ON",
                                    value: "ON",
                                    active: false,
                                },
                                {
                                    id: 2,
                                    active_id: "8.1.2",
                                    name: "OFF",
                                    value: "OFF",
                                    active: true,
                                }
                            ]
                        },
                    ]
                }
            ]
        },
    ],

    code: '',
    pdfImg: '',
    emailImg: '',
    emailImgBlob: {},
    headerMenu: [],
    footerMenu: [],
    dealers: [],
    active_dealer_email: '',
    regions: [],
    mobileMenuIdx: '',
    filterDealer: [],
});

const country = proxy({
    country1: [
        { label: "Afghanistan", value: "AF" },
        { label: "Åland Islands", value: "AX" },
        { label: "Albania", value: "AL" },
        { label: "Algeria", value: "DZ" },
        { label: "American Samoa", value: "AS" },
        { label: "Andorra", value: "AD" },
        { label: "Angola", value: "AO" },
        { label: "Anguilla", value: "AI" },
        { label: "Antarctica", value: "AQ" },
        { label: "Antigua and Barbuda", value: "AG" },
        { label: "Argentina", value: "AR" },
        { label: "Armenia", value: "AM" },
        { label: "Aruba", value: "AW" },
        { label: "Australia", value: "AU" },
        { label: "Austria", value: "AT" },
        { label: "Azerbaijan", value: "AZ" },
        { label: "Bahamas", value: "BS" },
        { label: "Bahrain", value: "BH" },
        { label: "Bangladesh", value: "BD" },
        { label: "Barbados", value: "BB" },
        { label: "Belarus", value: "BY" },
        { label: "Belgium", value: "BE" },
        { label: "Belize", value: "BZ" },
        { label: "Benin", value: "BJ" },
        { label: "Bermuda", value: "BM" },
        { label: "Bhutan", value: "BT" },
        { label: "Bolivia", value: "BO" },
        { label: "Bosnia and Herzegovina", value: "BA" },
        { label: "Botswana", value: "BW" },
        { label: "Bouvet Island", value: "BV" },
        { label: "Brazil", value: "BR" },
        { label: "British Indian Ocean Territory", value: "IO" },
        { label: "Brunei Darussalam", value: "BN" },
        { label: "Bulgaria", value: "BG" },
        { label: "Burkina Faso", value: "BF" },
        { label: "Burundi", value: "BI" },
        { label: "Cambodia", value: "KH" },
        { label: "Cameroon", value: "CM" },
        { label: "Canada", value: "CA" },
        { label: "Cape Verde", value: "CV" },
        { label: "Cayman Islands", value: "KY" },
        { label: "Central African Republic", value: "CF" },
        { label: "Chad", value: "TD" },
        { label: "Chile", value: "CL" },
        { label: "China", value: "CN" },
        { label: "Christmas Island", value: "CX" },
        { label: "Cocos (Keeling) Islands", value: "CC" },
        { label: "Colombia", value: "CO" },
        { label: "Comoros", value: "KM" },
        { label: "Congo", value: "CG" },
        { label: "Congo, The Democratic Republic of the", value: "CD" },
        { label: "Cook Islands", value: "CK" },
        { label: "Costa Rica", value: "CR" },
        { label: "Cote D'Ivoire", value: "CI" },
        { label: "Croatia", value: "HR" },
        { label: "Cuba", value: "CU" },
        { label: "Cyprus", value: "CY" },
        { label: "Czech Republic", value: "CZ" },
        { label: "Denmark", value: "DK" },
        { label: "Djibouti", value: "DJ" },
        { label: "Dominica", value: "DM" },
        { label: "Dominican Republic", value: "DO" },
        { label: "Ecuador", value: "EC" },
        { label: "Egypt", value: "EG" },
        { label: "El Salvador", value: "SV" },
        { label: "Equatorial Guinea", value: "GQ" },
        { label: "Eritrea", value: "ER" },
        { label: "Estonia", value: "EE" },
        { label: "Ethiopia", value: "ET" },
        { label: "Falkland Islands (Malvinas)", value: "FK" },
        { label: "Faroe Islands", value: "FO" },
        { label: "Fiji", value: "FJ" },
        { label: "Finland", value: "FI" },
        { label: "France", value: "FR" },
        { label: "French Guiana", value: "GF" },
        { label: "French Polynesia", value: "PF" },
        { label: "French Southern Territories", value: "TF" },
        { label: "Gabon", value: "GA" },
        { label: "Gambia", value: "GM" },
        { label: "Georgia", value: "GE" },
        { label: "Germany", value: "DE" },
        { label: "Ghana", value: "GH" },
        { label: "Gibraltar", value: "GI" },
        { label: "Greece", value: "GR" },
        { label: "Greenland", value: "GL" },
        { label: "Grenada", value: "GD" },
        { label: "Guadeloupe", value: "GP" },
        { label: "Guam", value: "GU" },
        { label: "Guatemala", value: "GT" },
        { label: "Guernsey", value: "GG" },
        { label: "Guinea", value: "GN" },
        { label: "Guinea-Bissau", value: "GW" },
        { label: "Guyana", value: "GY" },
        { label: "Haiti", value: "HT" },
        { label: "Heard Island and Mcdonald Islands", value: "HM" },
        { label: "Holy See (Vatican City State)", value: "VA" },
        { label: "Honduras", value: "HN" },
        { label: "Hong Kong", value: "HK" },
        { label: "Hungary", value: "HU" },
        { label: "Iceland", value: "IS" },
        { label: "India", value: "IN" },
        { label: "Indonesia", value: "ID" },
        { label: "Iran, Islamic Republic Of", value: "IR" },
        { label: "Iraq", value: "IQ" },
        { label: "Ireland", value: "IE" },
        { label: "Isle of Man", value: "IM" },
        { label: "Israel", value: "IL" },
        { label: "Italy", value: "IT" },
        { label: "Jamaica", value: "JM" },
        { label: "Japan", value: "JP" },
        { label: "Jersey", value: "JE" },
        { label: "Jordan", value: "JO" },
        { label: "Kazakhstan", value: "KZ" },
        { label: "Kenya", value: "KE" },
        { label: "Kiribati", value: "KI" },
        { label: "Korea, Democratic People'S Republic of", value: "KP" },
        { label: "Korea, Republic of", value: "KR" },
        { label: "Kuwait", value: "KW" },
        { label: "Kyrgyzstan", value: "KG" },
        { label: "Lao People'S Democratic Republic", value: "LA" },
        { label: "Latvia", value: "LV" },
        { label: "Lebanon", value: "LB" },
        { label: "Lesotho", value: "LS" },
        { label: "Liberia", value: "LR" },
        { label: "Libyan Arab Jamahiriya", value: "LY" },
        { label: "Liechtenstein", value: "LI" },
        { label: "Lithuania", value: "LT" },
        { label: "Luxembourg", value: "LU" },
        { label: "Macao", value: "MO" },
        { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
        { label: "Madagascar", value: "MG" },
        { label: "Malawi", value: "MW" },
        { label: "Malaysia", value: "MY" },
        { label: "Maldives", value: "MV" },
        { label: "Mali", value: "ML" },
        { label: "Malta", value: "MT" },
        { label: "Marshall Islands", value: "MH" },
        { label: "Martinique", value: "MQ" },
        { label: "Mauritania", value: "MR" },
        { label: "Mauritius", value: "MU" },
        { label: "Mayotte", value: "YT" },
        { label: "Mexico", value: "MX" },
        { label: "Micronesia, Federated States of", value: "FM" },
        { label: "Moldova, Republic of", value: "MD" },
        { label: "Monaco", value: "MC" },
        { label: "Mongolia", value: "MN" },
        { label: "Montserrat", value: "MS" },
        { label: "Morocco", value: "MA" },
        { label: "Mozambique", value: "MZ" },
        { label: "Myanmar", value: "MM" },
        { label: "Namibia", value: "NA" },
        { label: "Nauru", value: "NR" },
        { label: "Nepal", value: "NP" },
        { label: "Netherlands", value: "NL" },
        { label: "Netherlands Antilles", value: "AN" },
        { label: "New Caledonia", value: "NC" },
        { label: "New Zealand", value: "NZ" },
        { label: "Nicaragua", value: "NI" },
        { label: "Niger", value: "NE" },
        { label: "Nigeria", value: "NG" },
        { label: "Niue", value: "NU" },
        { label: "Norfolk Island", value: "NF" },
        { label: "Northern Mariana Islands", value: "MP" },
        { label: "Norway", value: "NO" },
        { label: "Oman", value: "OM" },
        { label: "Pakistan", value: "PK" },
        { label: "Palau", value: "PW" },
        { label: "Palestinian Territory, Occupied", value: "PS" },
        { label: "Panama", value: "PA" },
        { label: "Papua New Guinea", value: "PG" },
        { label: "Paraguay", value: "PY" },
        { label: "Peru", value: "PE" },
        { label: "Philippines", value: "PH" },
        { label: "Pitcairn", value: "PN" },
        { label: "Poland", value: "PL" },
        { label: "Portugal", value: "PT" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Qatar", value: "QA" },
        { label: "Reunion", value: "RE" },
        { label: "Romania", value: "RO" },
        { label: "Russian Federation", value: "RU" },
        { label: "RWANDA", value: "RW" },
        { label: "Saint Helena", value: "SH" },
        { label: "Saint Kitts and Nevis", value: "KN" },
        { label: "Saint Lucia", value: "LC" },
        { label: "Saint Pierre and Miquelon", value: "PM" },
        { label: "Saint Vincent and the Grenadines", value: "VC" },
        { label: "Samoa", value: "WS" },
        { label: "San Marino", value: "SM" },
        { label: "Sao Tome and Principe", value: "ST" },
        { label: "Saudi Arabia", value: "SA" },
        { label: "Senegal", value: "SN" },
        { label: "Serbia and Montenegro", value: "CS" },
        { label: "Seychelles", value: "SC" },
        { label: "Sierra Leone", value: "SL" },
        { label: "Singapore", value: "SG" },
        { label: "Slovakia", value: "SK" },
        { label: "Slovenia", value: "SI" },
        { label: "Solomon Islands", value: "SB" },
        { label: "Somalia", value: "SO" },
        { label: "South Africa", value: "ZA" },
        { label: "South Georgia and the South Sandwich Islands", value: "GS" },
        { label: "Spain", value: "ES" },
        { label: "Sri Lanka", value: "LK" },
        { label: "Sudan", value: "SD" },
        { label: "Suriname", value: "SR" },
        { label: "Svalbard and Jan Mayen", value: "SJ" },
        { label: "Swaziland", value: "SZ" },
        { label: "Sweden", value: "SE" },
        { label: "Switzerland", value: "CH" },
        { label: "Syrian Arab Republic", value: "SY" },
        { label: "Taiwan, Province of China", value: "TW" },
        { label: "Tajikistan", value: "TJ" },
        { label: "Tanzania, United Republic of", value: "TZ" },
        { label: "Thailand", value: "TH" },
        { label: "Timor-Leste", value: "TL" },
        { label: "Togo", value: "TG" },
        { label: "Tokelau", value: "TK" },
        { label: "Tonga", value: "TO" },
        { label: "Trinidad and Tobago", value: "TT" },
        { label: "Tunisia", value: "TN" },
        { label: "Turkey", value: "TR" },
        { label: "Turkmenistan", value: "TM" },
        { label: "Turks and Caicos Islands", value: "TC" },
        { label: "Tuvalu", value: "TV" },
        { label: "Uganda", value: "UG" },
        { label: "Ukraine", value: "UA" },
        { label: "United Arab Emirates", value: "AE" },
        { label: "United Kingdom", value: "GB" },
        { label: "United States", value: "US" },
        { label: "United States Minor Outlying Islands", value: "UM" },
        { label: "Uruguay", value: "UY" },
        { label: "Uzbekistan", value: "UZ" },
        { label: "Vanuatu", value: "VU" },
        { label: "Venezuela", value: "VE" },
        { label: "Viet Nam", value: "VN" },
        { label: "Virgin Islands, British", value: "VG" },
        { label: "Virgin Islands, U.S.", value: "VI" },
        { label: "Wallis and Futuna", value: "WF" },
        { label: "Western Sahara", value: "EH" },
        { label: "Yemen", value: "YE" },
        { label: "Zambia", value: "ZM" },
        { label: "Zimbabwe", value: "ZW" }
    ],
})

export { DB, country };
