import { useSnapshot } from "valtio";
import { DB } from "../store/store";
import modelImg from "../assets/images/model/model.png"

const ZoomImage = () => {
    const snap = useSnapshot(DB);
    const model = DB.models[0].config_image;
    
    const tube = DB.models[0].nodes[1].options[0].child.filter((f)=> f.active === true)[0];
    const upholstery = DB.models[0].nodes[2].options[0].child.filter((f)=> f.active === true)[0];
    const helm = DB.models[0].nodes[3].options[0].child.filter((f)=> f.active === true)[0];
    const sideguard = DB.models[0].nodes[3].options[1].child.filter((f)=> f.active === true)[0];
    const hull = DB.models[0].nodes[3].options[2].child.filter((f)=> f.active === true)[0];
    const deck = DB.models[0].nodes[4].options[0].child.filter((f)=> f.active === true)[0];
    const categories = DB.models[0].nodes[5].options[0].child;
    const rescue = DB.models[0].nodes[7].options[0].child[0].active;
    const rescueImg = DB.models[0].nodes[7].options[0].child.filter((f)=> f.active === true)[0];


    return (
        <div>
            <div id="model_img">
                <img src={model ? model : modelImg} alt="Boat" className="anchor" />

                {model && <img src={model} style={{zIndex: 0}} alt="Boat" />}
                {tube && <img src={tube.config_img} style={{zIndex: 1}} alt="Tubes" />}
                {upholstery && <img src={upholstery.config_img} style={{zIndex: 2}} alt="Upholstery" />}
                {sideguard && <img src={sideguard.config_img} style={{zIndex: 3}} alt="siderest" />}
                {hull && <img src={hull.config_img} style={{zIndex: 4}} alt="hull" />}
                {deck && <img src={deck.config_img} style={{zIndex: 5}} alt="Deck" />}
                {helm && <img src={helm.config_img} style={{zIndex: 6}} alt="helm" />}
                {rescue && <img src={rescueImg.config_img} style={{zIndex: 7}} alt="rescue" />}

                {categories.map((m, i) => (
                    <img key={m.id} className={`${m.active ? "enable" : "disable"}`} src={m.config_img} style={{zIndex: m.z_index}} alt="protectors" />  
                ))}

                {/* no data placeholder image */}
                {!model && <div> NO IMAGE</div>}
            </div>
        </div>
    )
}

export default ZoomImage