import React, { useEffect, useState } from 'react'
// import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useSnapshot } from 'valtio';

const ConfirmBox = ({ logoData, className, node, option, mainData, safety=false }) => {
    const snap = useSnapshot(DB);

    const handleClick = (index, val) => {
        DB.models[0].nodes[node].options[option].child.map((m)=> {
            m.active = false;
        })
        DB.models[0].nodes[node].options[option].child[index].active = true;
        
        // if(safety){
        //     DB.models[0].nodes[node].options[0].child.map((a)=> {
        //         a.active = DB.models[0].nodes[node].options[option].child[0].active;
        //     })
        // }
    }

    // useEffect(() => {
    //     DB.models[0].nodes[node].options[option].child.map((m, index) => {
    //         m.active = index === 1;
    //     });

    //     if(safety){
    //         DB.models[0].nodes[node].options[0].child.map((a)=> {
    //             a.active = DB.models[0].nodes[node].options[option].child[0].active;
    //         })
    //     }
    // }, []);

    return (
        <div className={`confirm_block ${className ? className : ''}`}>
            <div className='confirm_header'>{logoData[0].name}</div>
            <div className='confirm_options'>
                {logoData[0].child.map((m, i)=> (
                    <div key={m.id} className={`confirm_content ${m.active ? 'active' : ''}`} onClick={() => handleClick(i, m.name)}>{m.name}</div>
                ))}
            </div>
            {mainData === "Rescue mode" ? 
                <></>
                :
                logoData[0].child.map((m, i)=> (
                    <div key={i} className="confirm_header confirm_info"> {(m.name === "Yes" && m.active) && <><FontAwesomeIcon icon={faCircleInfo} /> <span>We require your logo to be sent to us in .eps or .ai format.</span></>}</div>
                ))
            }
        </div>
    )
}

export default ConfirmBox