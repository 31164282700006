import React from 'react'
import ColorPicker from './sub_components/ColorPicker'

const Deck = ({ item }) => {

    const mainData = item.nodes.filter((f) => f.node_name === "Deck");
    const BgImageData = mainData[0].options.filter((f) => f.name === "Deck Colour");
    // const BgImageData_2 = mainData[0].options.filter((f) => f.name === "Caulking Colour");

    return (
        <> 
            {mainData[0].active &&
                <div className='upholstery'>
                    <h4>{mainData[0].node_name}</h4>
                    {BgImageData[0].active && <ColorPicker
                        className="deck_picker"
                        colorData = {BgImageData}
                        node = {4}
                        option = {0} 
                    />}
                    {/* <ColorPicker
                        colorData = {BgImageData_2}
                        className="gelcoat_picker_bottom"
                        node = {4}
                        option = {1}
                    /> */}
                </div>
            }
        </>
    )
}

export default Deck