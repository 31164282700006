import React, { Fragment } from 'react'
import Accordion from './sub_components/Accordion'
import close_icon from "../assets/images/close_icon.svg"
import { useState } from 'react';

const FinishingTouches = ({ item }) => {
    const [displayImage, setDisplayImage] = useState(false);
    const [activeInfo, setActiveInfo] = useState('');

    const mainData = item.nodes.filter((f) => f.node_name === "Finishing Touches");

    const imageExpand = (option) => {
        setDisplayImage(true)
        setActiveInfo(option)
        const body = document.body
        body.classList.add('active_popup_modal')
    }
    const closePopup = () => {
        setDisplayImage(false)
        const body = document.body
        body.classList.remove('active_popup_modal')
    }

    return (
        <> 
            {mainData[0].active &&
                <div className='finish_block'>
                    <div>
                        <h4>{mainData[0].node_name}</h4>
                    </div>

                    {mainData[0].options.length ? 
                        mainData[0].options.map((m, i)=>(
                            <Fragment key={i}>
                                {
                                    m.child.length ? <Accordion 
                                    key={i}
                                    mainData = {m}
                                    node = {6}
                                    option= {i}
                                    imageExpand = {imageExpand}
                                    />
                                    : <></>
                                }
                            </Fragment>
                        ))
                        :
                        <div>No options available</div>
                    }

                    {mainData[0].options.map((m, j)=>(
                        <Fragment key={j}>
                            {
                                m.child.map((a, i) => (
                                    <Fragment key={i}>
                                        {
                                            (displayImage && activeInfo === (j+'_'+i)) ? 
                                            <div className='image_block'>
                                                <div className='close_icon' onClick={closePopup}>
                                                    <img src={close_icon} alt="Close Icon" width="16px"/>
                                                </div>
                                                <div className='row'>
                                                    {a.img_src && 
                                                        <div className='col pb-4 pb-md-0'>
                                                            <img className='w-100 info_image' src={a.img_src} alt="image" />
                                                        </div>
                                                    }
                                                    <div className={`col ${a.img_src ? 'col-12 col-md-6' : 'col-12'}`}>
                                                        <div className='popup_para_block'>
                                                            <div className='popup_name'>{a.name}</div>
                                                            <div className='popup_text'>{a.info}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            :
                                            " "
                                        }
                                    </Fragment>
                                    
                                ))
                            }
                        </Fragment>
                    ))}
                </div>
            }
        </>
    )
}

export default FinishingTouches