import { DB } from "../store/store";
import "./RescueMode.scss";
import ConfirmBox from "./sub_components/ConfirmBox";

const RescueMode = ({ item }) => {

	const mainData = item.nodes.filter((f) => f.node_name === "Rescue mode");
    const logoData = mainData[0].options.filter((f) => f.name === "View SOLAS Rescue Mode");

	return (
		<>
			{logoData[0].active && <ConfirmBox
				mainData={mainData[0].node_name}
				logoData={logoData}
				node = {7}
				option = {0}
				safety = {true}
			/>}
		</>
	)
}

export default RescueMode