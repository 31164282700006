import { useState } from 'react';
import close_icon from "../assets/images/close_icon.svg";
import './GenerateCode.scss';
import copy_icon from "../assets/images/copy_icon.svg";
import { useNavigate, useParams } from 'react-router-dom/dist';
import copy from 'copy-to-clipboard';

const GenerateCode = ({ onDrop, displayCode, code }) => {
    const [decodeInpt, setDecodeInpt] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const [copied, setCopied] = useState();
    const { label, cat, model } = params;

    const copyCodeToClipboard = () => {
        copy(code);
        setCopied(1)

        setTimeout(() => {
            setCopied()
        }, 3000);
    }

    const displayPanel = async () => {
        if (decodeInpt) {
            if (label) {
                navigate(`/${label}/${cat}/${model}/?share=${decodeInpt}`);
            } else {
                navigate(`/${cat}/${model}/?share=${decodeInpt}`);
            }
            // event.preventDefault();
            window.location.reload();
            setLoading(true);
        }
    }

    return (
        <div className={`code_modal ${displayCode} generate-box`}>
            <div className='close_icon' onClick={onDrop}>
                <img src={close_icon} alt="Close Icon" width="16px"/>
            </div>
            
            <div className='display_code'>
                <div className='display_code_title'>
                    <div className='load_block_title'>Generate Code:</div> <div>{code}</div>
                    {code && <img style={{ cursor: 'pointer', width: "45px", height: "20px", display: "inline", margin: "0", transform: "translate(10px, -2px)" }} src={copy_icon} alt='copyicon' onClick={copyCodeToClipboard} />}
                    <div>
                        <p className='clipboard_copy_share m-0 fw-bold'>{copied === 1 ? 'Copied' : ' '}</p>
                    </div>
                </div>
            </div>

            <hr style={{color: "#999999", opacity: 1, width: "100%"}}/>

            <div className='load_block pt-2'>
                <div className='load_block_title'>Load Code</div>
                <div>
                    <input type="text" value={decodeInpt} onChange={(e) => { setDecodeInpt(e.target.value) }} />
                </div>
                <div>
                    {loading ? "Loading..." : <button onClick={() => displayPanel()}>Load</button>}
                </div>
            </div>
        </div>
    )
}

export default GenerateCode