import React from 'react'
// import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';

const AddImage = ({ Protectors, node, option }) => {

    // const snap = useSnapshot(DB);

    const handleClick = (index, cat) => {

        const index_id = DB.models[0].nodes[node].options[option].child[index].id;

        const cat_list = DB.models[0].nodes[node].options[option].child.filter((f) => f.category === cat);

        cat_list.map((m)=> {
            if(m.id !== index_id){
                m.active = false;
            }
        })

        DB.models[0].nodes[node].options[option].child[index].active = !DB.models[0].nodes[node].options[option].child[index].active;
    }

    return (
        <div className='image_main_block'>
            {Protectors[0].child.length ?
                Protectors[0].child.map((m, i)=> (
                    <div key={m.id} className='single_block'>
                        <div className={`single_image ${m.active ? 'block_active' : ''}`}><img src={m.value} alt={m.name} /></div>
                        <p className='image_text'>{m.name}</p>
                        <button onClick={() => handleClick(i, m.category)} className={`image_button ${m.active ? 'active' : ''}`}>{!m.active ? <span>+ Add</span> : <span>Remove</span>}</button>
                    </div>
                ))
                :
                <div>No options available</div>
        }

        </div>
    )
}

export default AddImage