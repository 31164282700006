import React from 'react'
import ColorPicker from './sub_components/ColorPicker'
import ConfirmBox from './sub_components/ConfirmBox'

const Upholstery = ({ item }) => {

    const mainData = item.nodes.filter((f) => f.node_name === "Upholstery");
    const colorData = mainData[0].options.filter((f) => f.name === "Upholstery Colour");
    const logoData = mainData[0].options.filter((f) => f.name === "Would you like to add your own logo to the upholstery?");

    return (
        <> 
            {mainData[0].active &&
                <div className='upholstery'>
                    <h4>{mainData[0].node_name}</h4>
                    {colorData[0].active && <ColorPicker
                        colorData = {colorData}
                        type = "color"
                        node = {2}
                        option = {0} 
                    />}
                    {logoData[0].active && <ConfirmBox
                        logoData = {logoData}
                        className = "confirm_spacing"
                        node = {2}
                        option = {1}
                    />}
                </div>
            }
        </>
    )
}

export default Upholstery