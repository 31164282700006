import React from 'react'
import Selector from './sub_components/Selector';

const Engine = ({ item }) => {

    const mainData = item.nodes.filter((f)=> f.node_name === "Engine");
    const selectorData = mainData[0].options.filter((f) => f.name === "Power Options");

    return (
        <> 
            {mainData[0].active &&
                <div className='engine'>
                
                    <h4>{mainData[0].node_name}</h4>
                    <div>
                        {selectorData[0].active && <Selector 
                            selectorData={selectorData}
                            node = {0}
                            option = {0} 
                        />}
                    </div>
                </div>
            }
        </>
    )
}

export default Engine