import React from 'react'
// import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';

const ColorPicker = ({ className, colorData, node, option }) => {
    // const snap = useSnapshot(DB);

    const handleClick = (index) => {
        DB.models[0].nodes[node].options[option].child.map((m)=> {
            m.active = false;
        })
        DB.models[0].nodes[node].options[option].child[index].active = true;
    }

    const colorActive = colorData ? colorData[0].child.filter((f) => f.active === true)[0] : [];

    let title = colorData[0].name;

    
    switch(colorData[0].name) {
        case "Choose your tube pod colour":
            if (DB.models[0].name.includes("SportJet")){
                title = "Choose your cleat pod colour";
            }
            if (DB.models[0].name.includes("DieselJet")){
                title = "Choose your step pad colour";
            }
        break;
        default:
          // code block
      }
    

    return (
        <div className={`picker ${className ? className : ''}`}>
            <div className='picker_header'>{title}</div>
            <div className='picker_block'>
                {colorData[0].child.length ? 
                    colorData[0].child.map((m, i)=> (
                        <React.Fragment key={m.id}>
                            <div title={m.name} key={m.id} style={{ backgroundColor: m.value, backgroundImage: 'url(' + m.icon + ')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className={`picker_color ${m.active ? 'active' : ''}`} onClick={() => handleClick(i)}></div>
                        </React.Fragment>
                    )) 
                    :
                    <React.Fragment key={0}>
                        <div>No options available</div>
                    </React.Fragment>
                }
            </div>
            <p className='picker_content'>{colorActive?.name}</p>
        </div>
    )
}

export default ColorPicker