import React from 'react'
// import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';

const Selector = ({ selectorData, node, option }) => {
    // const snap = useSnapshot(DB);

    

    const handleClick = (index) => {
        DB.models[0].nodes[node].options[option].child.map((m)=> {
            m.active = false;
        })
        DB.models[0].nodes[node].options[option].child[index].active = true;
    }

    return (
        <div className='options'>
            <div>
                <div className='option_header'>{selectorData[0].name}</div>
            </div>

            {selectorData[0].child.length ?
                selectorData[0].child.map((m, i)=> (
                    <div key={m.id} className='option_values'>
                        <div className={`option_content ${m.active ? 'active' : ''}`} onClick={() => handleClick(i)}>
                            {m.name}
                            <span className='option_sub_content'>
                                {m.desc}
                            </span>
                        </div>
                    </div>
                ))
                :
                    <div>No options available</div>
            }


        </div>
    )
}

export default Selector