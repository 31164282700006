import { Controller, useForm } from "react-hook-form"
import "./Checkout.scss"
import Select, { createFilter } from 'react-select';
import { useSnapshot } from "valtio";
import { DB, country } from "./store/store";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "./componenets/ShareModal";
import { useState, useRef  } from "react";
import share_button from "../src/assets/images/model/share_button.svg";
import { useEffect } from "react";
import Download from "./componenets/Download";
import { postData } from "./api/api";
import { postFormData } from "./api/api";
import Header from "./Header";
import FooterMenu from "./componenets/FooterMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import { usePDF, Document } from '@react-pdf/renderer'; // Document is needed for tender image to work/show in attachment PDF.
import AttachPDF from './ui/pdf/AttachPDF';

const Checkout = () => {
    const snap = useSnapshot(DB);
    const snap2 = useSnapshot(country);
    const navigate = useNavigate();
    const [displayShare, setDisplayShare] = useState()
    const params = useParams();
    const { label, cat, model } = params;
    const [url, setUrl] = useState('');
    const [detailsSubmit, setDetailsSubmit] = useState('Submit');
    const [successMsg, setSuccessMsg] = useState(false);
    const [requestCallback, setRequestCallback] = useState('Request Call Back');
    const [successMsg2, setSuccessMsg2] = useState(false);
    const [displayPopUp, setDisplayPopUp] = useState(false);
    let specs = '';
    let options = '';
    const newLabel = decodeURIComponent(label);
    const finalLabel = newLabel.replace(/_/g, ' ');
    specs += "Tender Name: " + DB.models[0].name + "\n";
    const body = document.body;

    body.classList.remove('dark-mode');

    // Object.keys(DB.models[0].specifications[0]).map(key => {
    //     specs += "\n\n" + key + ": " + DB.models[0].specifications[0][key];
    // })
    // DB.models[0].name.includes("SOLAS")

    snap.models[0].nodes.map((m, i) => {
        if (m.node_name !== "Rescue mode"){
            options += i === 0 ? m.node_name + ": " : "\n\n" + m.node_name + ": ";
            m.options.map((n, j) => (
                n.child.map((o, k) => {
                    if (m.id !== 8 && o.active === true) {
                        options += "\n" + n.name + ": " + o.name;
                    }
                })
            ))
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const newDealer = snap.dealers
        .map(dealer => dealer.options.find(option => option.label === finalLabel))
        .filter(option => option !== undefined);

        const newLabels = new Set();
        const uniqueDealers = newDealer.filter(option => {
            if (newLabels.has(option.label)) {
                return false;
            } else {
                newLabels.add(option.label);
                return true;
            }
        });
        
        if (newDealer.length > 0) {
            DB.filterDealer = uniqueDealers;
        } else {
            DB.filterDealer = snap.dealers;
        }
    }, [])

    // if (DB.models[0].name.includes("SOLAS")){
    //     options += "\n\n Rescue mode: ";
    //     options += "\n" + snap.models[0].nodes[7].options[1].name + ": Yes";
    // }

    // console.log(specs, options);

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control
    } = useForm();

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        control: control2
    } = useForm();

    // console.log(DB.emailImg);
    // const img = snap.emailImg;
    const [instance, updateInstance] =  usePDF({ document: <AttachPDF objs={snap} img={snap.emailImg} /> });
    


    const onSubmit = async (data) => {
        // console.log(data, data.pdf_file)
        setDetailsSubmit('Sending ...');
        let id = 0;

        // var form = new FormData();
        // form.append("input_2", data.name);
        // form.append("input_4", instance.blob, "test.pdf");

        // await postFormData(process.env.REACT_APP_API_URL +"gf/v2/forms/9/submissions", form).then((data) => {
        //     console.log(data); // JSON data parsed by `data.json()` call
        // });

        let newImg;
        await fetch(DB.emailImg)
        .then(res => res.blob())
        .then(r => {
            console.log(r)
            newImg = r;
        })

        // const img = DB.emailImg;
        var formdata = new FormData();
        formdata.append("input_1", data.name);
        formdata.append("input_3", data.email);
        formdata.append("input_4", data.tel);
        formdata.append("input_5", data.country.label + " - " + data.country.value);
        formdata.append("input_6", data.yatch_make);
        formdata.append("input_7", data.yatch_model);
        formdata.append("input_8", data.dealer.label + " - " + data.dealer.value);
        formdata.append("input_9", data.additional_info);
        formdata.append("input_18", specs);
        formdata.append("input_18", options);
        formdata.append("input_20", snap.active_dealer_email);
        formdata.append("input_21", instance.blob, "WJT.pdf");
        // formdata.append("input_22", img);
        formdata.append("input_23", newImg, "Tender.png");
        formdata.append("input_24", DB.models[0].name);
        formdata.append("input_15", data.checkbox1);
        formdata.append("input_16", data.checkbox2);
        
        await postFormData(process.env.REACT_APP_API_URL +"gf/v2/forms/4/submissions", formdata).then((data) => {
            // console.log(data); // JSON data parsed by `data.json()` call
            // id = data.id;
            if (data.is_valid){
                setSuccessMsg(true);
                // setTimeout(() => {
                //     setSuccessMsg(false);
                // }, 6000);
            }else{
                alert ("Server error, please try after sometime.");
            }
        });

        reset();
        setDetailsSubmit('Submit');
    };

    const onSubmitEmail = async (data) => {
        setRequestCallback('Sending ...');
        let id = 0;
        await postData(process.env.REACT_APP_API_URL +"gf/v2/entries", { 
            'form_id': 5, 
            1: data.name1, 
            3:  data.email1, 
            4:  data.tel1, 
            5: data.country1.label + " - " + data.country1.value, 
        }).then((res) => {
            id = res.id;
        });

        await postData(process.env.REACT_APP_API_URL +"gf/v2/entries/"+id+"/notifications", { 
            'form_id': 5 
        }).then((res2) => {
            setSuccessMsg2(true);
            // setTimeout(() => {
            //     setSuccessMsg2(false);
            // }, 5000);
        });

        reset2();
        setRequestCallback('Request Call Back');
    };

    const openModel = () => {
        setDisplayPopUp('active')
        const body = document.body
        body.classList.add('active_code_modal')
    }
    const closeModel = () => {
        setDisplayPopUp()
        const body = document.body
        body.classList.remove('active_code_modal')
    }
    const closeShare = () => {
        setDisplayShare()
        const body = document.body
        body.classList.remove('active_code_modal')
    }
    const mailAFriend = () => {
        setDisplayShare()
        const body = document.body
        body.classList.remove('active_code_modal')
    }

    const onChange = () => {}
    
    useEffect(() => {
        let currentUrl = '';
        if (label) {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${label}/${cat}/${model}/?share=` + DB.code;
        } else {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${cat}/${model}/?share=` + DB.code;
        }
        setUrl(currentUrl);
    }, [DB.code])

    useEffect(() => {

        if (DB.models[0].name === "" || DB.models[0].name === undefined){
            console.log("empty");
            alert("Something went wrong. You will be redirected to the main page shortly...")
            window.location.href = '/';
        }

        const handleBackButtonEvent = (e) => {
            e.preventDefault();
            const confirmLeave = window.confirm("Your changes will be lost if you leave this page. Do you want to leave?");
            if (confirmLeave) {
                navigate(-1);
            } else {
                window.history.pushState(null, null, window.location.pathname);
            }
        };

        window.addEventListener('popstate', handleBackButtonEvent);
        window.history.pushState(null, null, window.location.pathname);

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = "Your changes will be lost if you leave this page.";
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('popstate', handleBackButtonEvent);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [navigate]);

    let optionTitleFlag = false;

    const getPodColorName = (modelName, optionName) => {
        if (modelName.includes("SportJet") && optionName === "Choose your tube pod colour") {
            return "Choose your cleat pod colour";
        } else if (modelName.includes("DieselJet") && optionName === "Choose your tube pod colour") {
            return "Choose your step pad colour";
        }
        return optionName;
    }

    return (
        <>
            <div className="top-wrapper">
                <Header />
                <div className="checkout-content-block">
                    <div className="max-wrap2">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <h3 className="checkout-title">The final steps...</h3>
                            </div>
                            <div className="col-md-12 col-lg-6 checkout-para">
                                Just a few more steps and you'll receive your no obligation quote for your tender, designed to your specification outlined below.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="two-column-accordion-layout">
                    <div className="max-wrap2">
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-md-12 col-lg-6 checkout-content-area">
                                <div className="content-sub-para pb-5">
                                    <h4>Your {DB.models[0].name}</h4>
                                    <p>Here are the details of your tender build</p>
                                </div>

                                <div className="accordion accordion-flush pb-3" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Specification
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body specs">
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">LOA</span> <span className="text-end">{DB.models[0].specifications[0].loa} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">BEAM</span> <span className="text-end"> {DB.models[0].specifications[0].beam}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">DRY WEIGHT</span> <span className="text-end">{DB.models[0].specifications[0].dry_weight}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">OVERALL HEIGHT</span> <span className="text-end">{DB.models[0].specifications[0].overall_height} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">ENGINE</span> <span className="text-end">{DB.models[0].specifications[0].engine} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">FUEL CAPACITY</span> <span className="text-end">{DB.models[0].specifications[0].fuel_capacity} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">MAX SPEED</span> <span className="text-end">{DB.models[0].specifications[0].max_speed}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">TYPICAL FITMENT</span> <span className="text-end">{DB.models[0].specifications[0].typical_fitment} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">SEATING</span> <span className="text-end">{DB.models[0].specifications[0].seating} </span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Your Chosen Options
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                {
                                                    snap.models[0].nodes.map((m, i) => {
                                                        optionTitleFlag = true;
                                                        return (
                                                            m.options.map((n, j) => (
                                                                n.child.map((o, k) => {
                                                                    if (m.id !== 8 && o.active === true) {
                                                                        return (
                                                                            <div key={k} className={`parent mb-3 d-flex flex-column flex-lg-row pb-3 ${optionTitleFlag && 'border-top pt-4'}`}>
                                                                                <div className="child1 fw-bold">
                                                                                    <div className="pb-2 pb-lg-0">
                                                                                        {
                                                                                            optionTitleFlag ? m.node_name : ''
                                                                                        }
                                                                                        {optionTitleFlag = false}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="child2 d-flex">
                                                                                    <div className="name2 pe-3">
                                                                                        {getPodColorName(DB.models[0].name, n.name)}
                                                                                    </div>
                                                                                    <div className="name3">
                                                                                        {o.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            ))
                                                        )
                                                    })
                                                }

                                                {snap.models[0]?.name?.split(" ")[0] === "SOLAS" &&
                                                    <div className="parent mb-3 d-flex flex-column flex-lg-row border-top pt-3">
                                                        <div className="child1 fw-bold pb-2 pb-lg-0">
                                                            <div>Rescue mode</div>
                                                        </div>

                                                        <div className="child2 d-flex">
                                                            <div className="name2 pe-3">
                                                                {snap.models[0].nodes[7].options[0].name}
                                                            </div>
                                                            <div className="name3">
                                                                {snap.models[0].nodes[7].options[0].child[0].active ? "ON" : "OFF"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons d-flex flex-wrap">
                                    <a 
                                        className="btn btn_dark d-flex align-items-center" 
                                        onClick={() => 
                                            window.open(`mailto:?subject=Checkout this ${DB.models[0].name}&body=Check this link ${encodeURI(url)}`, '_blank')
                                        }
                                    >
                                        <span className="pe-2"><img src="/images/Checkout/email-icon.png" /></span>Email to a friend
                                    </a>
                                    <a onClick={openModel} className="btn btn_dark d-flex align-items-center">
                                        <span className="pe-2"><img src="/images/Checkout/download-icon.png" /></span> Download
                                    </a>
                                    {/* <a href="" className="btn btn_dark d-flex align-items-center"><span className="pe-2"><img src="/images/Checkout/download-icon.png" /></span>Download PDF</a> */}

                                    <a  className="btn btn_white btn_acc d-flex align-items-center"
                                        onClick={() => {
                                            const body = document.body
                                            body.classList.add('active_code_modal')
                                            setDisplayShare('active')
                                        }}
                                    >
                                        <span><img src={share_button} alt="Share Buttton" /> Share Options</span>
                                    </a>
                                    {/* <a className="btn btn_white btn_acc d-flex align-items-center" onClick={() => navigate(-1)}><span className="pe-2"><img src="/images/Checkout/reset-icon.png" /></span>Make Changes</a> */}
                                    {label ? 
                                        <a className="btn btn_white btn_acc d-flex align-items-center" onClick={() => window.location.href = `/${label}/${cat}/${model}/?share=` + DB.code }><span className="pe-2"><img src="/images/Checkout/reset-icon.png" /></span>Make Changes</a>
                                    :
                                        <a className="btn btn_white btn_acc d-flex align-items-center" onClick={() => window.location.href = `/${cat}/${model}/?share=` + DB.code }><span className="pe-2"><img src="/images/Checkout/reset-icon.png" /></span>Make Changes</a>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 accordion-img">
                                {/* <img className="w-100" src="/images/Checkout/checkout.png" alt="" /> */}
                                <img className="w-100" src={DB.pdfImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-wrap">
                    <ShareModal onDrop={closeShare} displayShare={displayShare} url={url} code={DB.code} />
                    <Download onDrop={closeModel} displayPopUp={displayPopUp}/>
                </div>

                <div className="details-form">
                    <div className="max-wrap">
                        {successMsg ?
                            <div style={{ color: '#6d9b36'}} className="d-flex flex-column">
                                <div><FontAwesomeIcon icon={faCircleInfo} /> <span>Thanks for contacting us! We will get in touch with you shortly.</span></div>
                            </div>
                            :
                            <form key={1} onSubmit={handleSubmit(onSubmit)} id="checkoutform">
                                <div className="row form-block">
                                    <div className="col-md-12 col-lg-6 pb-5 pb-0">
                                        <div>
                                            <h4 className="form-title">Your Details</h4>
                                        </div>
                                        <div>
                                            <div className="mb-3">
                                                <input
                                                    id="name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Full Name*"
                                                    {...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: "Full Name is required"
                                                        },
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.name?.message}</p>
                                            </div>

                                            <div className="mb-3">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Email Address*"
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: "Email is required"
                                                        },
                                                        pattern: {
                                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                            message: "Invalid email format"
                                                        }
                                                    })} />
                                                <p style={{ color: "red" }}>{errors.email?.message}</p>
                                            </div>

                                            <div className="mb-3">
                                                <input
                                                    type="tel"
                                                    id="tel"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    {...register("tel", {
                                                        pattern: {
                                                            value: /^[\d\s()+-]+$/,
                                                            message: "Invalid phone number format"
                                                        }
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.tel?.message}</p>
                                            </div>

                                            {/* <div className="mb-3">
                                                <input
                                                    type="country"
                                                    id="country"
                                                    className="form-control"
                                                    placeholder="Please Select Country.*"
                                                    {...register("country", {
                                                        required: {
                                                            value: true,
                                                            message: "Country is required"
                                                        },
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.country?.message}</p>
                                            </div> */}

                                            <Controller
                                                control={control}
                                                name="country"
                                                rules={{
                                                    required: "Please Select Country.",
                                                }}
                                                defaultValue=""
                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                    <div className="mb-3">
                                                        <Select
                                                            name={name}
                                                            ref={ref}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            options={snap2.country1}
                                                            getOptionLabel={(e) => e.label}
                                                            getOptionValue={(e) => e.value}
                                                            placeholder="Select a Country*"
                                                            closeMenuOnSelect={true}
                                                            filterOption={createFilter({ matchFrom: "start" })}
                                                        />
                                                        <div style={{ color: "red" }}>
                                                            {errors.country && errors.country.message}
                                                        </div>
                                                    </div>
                                                )}
                                            />

                                            <div className="form-checkbox">
                                                <div className="d-flex align-items-start align-items-md-center pb-2">
                                                    <input
                                                        style={{ verticalAlign: "middle" }}
                                                        type="checkbox"
                                                        id="exampleCheck1"
                                                        value="I am happy for Williams to contact me with a no obligation quote"
                                                        {...register("checkbox1")}
                                                    />
                                                    <label className="form-check-label ps-2" htmlFor="exampleCheck1">I am happy for Williams to contact me with a no obligation quote</label>
                                                </div>

                                                <div className="d-flex align-items-start align-items-md-center">
                                                    <input
                                                        style={{ verticalAlign: "middle" }}
                                                        type="checkbox"
                                                        id="exampleCheck2"
                                                        value="I am happy to receive further marketing communication from Williams"
                                                        {...register("checkbox2")}
                                                    />
                                                    <label className="form-check-label ps-2" htmlFor="exampleCheck2">I am happy to receive further marketing communication from Williams</label>
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    {errors.language && errors.language.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <div>
                                            <h4 className="form-title">Your Yacht Information</h4>
                                        </div>
                                        <div>
                                            <div className="mb-3">
                                                <input
                                                    id="yatch_make"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Yacht Make"
                                                    {...register("yatch_make", {
                                                        pattern: {
                                                            // value: /^[a-z][a-z\s]*$/,
                                                            // message: "Only characters and spaces are allowed"
                                                        }
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.yatch_make?.message}</p>
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    id="yatch_model"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Yacht Model"
                                                    {...register("yatch_model", {
                                                        pattern: {
                                                            // value: /^(0|[1-9]\d*)$/,
                                                            // message: "Only numbers are allowed"
                                                        }
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.yatch_model?.message}</p>
                                            </div>

                                            {/* <div className="mb-3">
                                                <input
                                                    type="dealer"
                                                    id="dealer"
                                                    className="form-control"
                                                    placeholder="Select a Dealer.*"
                                                    {...register("dealer", {
                                                        required: {
                                                            value: true,
                                                            message: "Dealer is required"
                                                        },
                                                    })}
                                                />
                                                <p style={{ color: "red" }}>{errors.dealer?.message}</p>
                                            </div> */}

                                            <Controller
                                                control={control}
                                                name="dealer"
                                                rules={{
                                                    required: "Please select a Dealer.",
                                                }}
                                                defaultValue=""
                                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                    <div className="mb-3">
                                                        <Select
                                                            name={name}
                                                            ref={ref}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                DB.active_dealer_email = e.email;
                                                            }}
                                                            // menuIsOpen={true}
                                                            className="react-select-container"
                                                            onBlur={onBlur}
                                                            value={value}
                                                            options={snap.filterDealer}
                                                            getOptionLabel={(e) => e.label}
                                                            getOptionValue={(e) => e.value}
                                                            placeholder="Select a Dealer*"
                                                            closeMenuOnSelect={true}
                                                        />
                                                        <div style={{ color: "red" }}>
                                                            {errors.dealer && errors.dealer.message}
                                                        </div>
                                                    </div>
                                                )}
                                            />

                                            <div className="mb-3">
                                                <input
                                                    id="additional_info"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Additional Info"
                                                    {...register("additional_info")}
                                                />
                                            </div>
                                            
                                            <div className="d-inline-block float-lg-end pt-3">
                                                <button type="submit" className="btn btn-primary btn-submit">{detailsSubmit} <span><img src="/images/Checkout/arrow-icon.png" /></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                        
                    </div>
                </div>

                <div className="details-form details-form-2">
                    <div className="max-wrap">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 pb-5 pb-lg-0">
                                <div>
                                    <h4 className="form-title">Request a call back</h4>
                                </div>
                                <div className="pe-lg-5">
                                    If you'd prefer to speak to someone about your requirements, simply fill out the form and one of our team will get back to you.
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div>
                                    <h4 className="form-title">{successMsg2 ? <span>&nbsp;</span> : 'Your Details'}</h4>
                                </div>
                                <div>
                                    {successMsg2 ?
                                        <div style={{ color: '#6d9b36'}}>
                                            <FontAwesomeIcon icon={faCircleInfo} /> <span>Thanks for contacting us! We will get in touch with you shortly.</span>
                                        </div>
                                        :
                                        <form key={2} onSubmit={handleSubmit2(onSubmitEmail)} id="requestcallback">
                                            <div className="mb-3">
                                                <input
                                                    id="name1"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Full Name*"
                                                    {...register2("name1", {
                                                        required: {
                                                            value: true,
                                                            message: "Full Name is required"
                                                        },
                                                    })} />
                                                <p style={{ color: "red" }}>{errors2.name1?.message}</p>
                                            </div>

                                            <div className="mb-3">
                                                <input
                                                    type="email"
                                                    id="email1"
                                                    className="form-control"
                                                    placeholder="Email Address*"
                                                    {...register2("email1", {
                                                        required: {
                                                            value: true,
                                                            message: "Email is required"
                                                        },
                                                        pattern: {
                                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                            message: "Invalid email format"
                                                        }
                                                    })} />
                                                <p style={{ color: "red" }}>{errors2.email1?.message}</p>
                                            </div>

                                            <div className="mb-3">
                                                <input
                                                    type="tel"
                                                    id="tel1"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    {...register2("tel1", {
                                                        required: {
                                                            value: true,
                                                            message: "Phone number is required"
                                                        },
                                                        pattern: {
                                                            value: /^[\d\s()+-]+$/,
                                                            message: "Invalid phone number format"
                                                        }
                                                    })} />
                                                <p style={{ color: "red" }}>{errors2.tel1?.message}</p>
                                            </div>

                                            <div className="mb-3">
                                                <Controller
                                                    control={control2}
                                                    name="country1"
                                                    rules={{
                                                        required: "Please Select Country.",
                                                    }}
                                                    defaultValue=""
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <div className="mb-3">
                                                            <Select
                                                                name={name}
                                                                ref={ref}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                options={snap2.country1}
                                                                getOptionLabel={(e) => e.label}
                                                                getOptionValue={(e) => e.value}
                                                                placeholder="Select a Country*"
                                                                closeMenuOnSelect={true}
                                                                filterOption={createFilter({ matchFrom: "start" })}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {errors2.country1 && errors2.country1.message}
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>

                                            <div className="d-inline-block float-lg-end pt-3">
                                                <button type="submit" className="btn btn-primary btn-submit" id="submit2">{ requestCallback }<span><img src="/images/Checkout/arrow-icon.png" /></span></button>
                                            </div>
                                        </form>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterMenu />
        </>
    )
}

export default Checkout