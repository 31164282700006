import ViewDetails from './ViewDetails';
import { PDFDownloadLink } from '@react-pdf/renderer';

import './ViewPdf.scss';
import pdf_icon from "../../assets/images/pdf_icon.svg"
import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';

const ViewPdf = () => {
    const snap = useSnapshot(DB);

    const tube = snap.models[0].nodes.filter((f) => f.node_name === "Tubes");
    const tube_color = tube[0].options.filter((f) => f.name === "Tube Colour");

    return (
        <>
            <PDFDownloadLink document={<ViewDetails objs={snap} />} fileName="WJT.pdf">
                { ({ loading }) => (<button><span><img src={pdf_icon} alt="PDF icon" width="16px" /></span> <span>{ loading ? 'Loading document...' : 'Download PDF' }</span></button>)}
            </PDFDownloadLink>
        </>
    )
}

export default ViewPdf