import { useEffect, useState } from 'react';
import close_icon from "../assets/images/close_icon.svg";
import './GenerateCode.scss';
import copy from "copy-to-clipboard";
import copy_icon from "../assets/images/copy_icon.svg";
import facebook from '../assets/social/facebook-f.svg'
import twitter from '../assets/social/x-twitter.svg'
import whatsapp from '../assets/social/whatsapp.svg'
import mail from '../assets/social/envelope-regular.svg'
import linkedin from '../assets/social/linkedin-in.svg'
import { DB } from '../store/store';

const ShareModal = ({ onDrop, displayShare, url, code }) => {
    const [copied, setCopied] = useState();

    const copyURLToClipboard = () => {
        copy(url);
        setCopied(2)

        setTimeout(() => {
            setCopied()
        }, 3000);
    }

    return (
        <div className={`code_modal ${displayShare} share-drop`}>
            <div className='close_icon' onClick={onDrop}>
                <img src={close_icon} alt="Close Icon" width="16px"/>
            </div>
            <div className='social-icon-block'>
                <div>
                    <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`} target='_blank'>
                        <img src={linkedin} alt='linkedin' />
                    </a>
                    <a href={`https://api.whatsapp.com/send?text=${url}`} target='_blank'>
                        <img src={whatsapp} alt='whatsapp' />
                    </a>
                    <a href={`http://www.twitter.com/intent/tweet?url=${url}`} target='_blank'>
                        <img src={twitter} alt='twitter' />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target='_blank'>
                        <img src={facebook} alt='facebook' />
                    </a>
                    <a 
                        onClick={() => window.open(`mailto:?subject=Checkout this ${DB.models[0].name}&body=Check this link ${encodeURI(url)}`, '_blank')}
                    >
                        <img src={mail} alt='mail' />
                    </a>
                </div>
                <div>
                    {url}
                    {code && <img style={{ cursor: 'pointer', width: "45px", height: "20px", display: "inline", margin: "0", transform: "translate(10px, -2px)" }} src={copy_icon} alt='copyicon' onClick={copyURLToClipboard} />}
                    <br />
                    <br />
                    <p className='clipboard_copy_share fw-bold'>{copied === 2 ? 'Copied' : ' '}</p>
                </div>
                
            </div>
        </div>
    )
}

export default ShareModal