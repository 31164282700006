import Checkout from './Checkout';
import Tenders from './Tenders';
import Visualiser from './Visualiser'

export default [
	{
		path: "/",
		children: [
			{
				index: true,
				element: <Tenders />
			},
			{
				path: '/:cat/:model/',
				element : <Visualiser /> ,
			},
		]
	},
	{
		path: "/:label",
		children: [
			{
				index: true,
				element: <Tenders />
			},
			{
				path: '/:label/:cat/:model/',
				element : <Visualiser /> ,
			},
			{
				path: '/:label/:cat/:model/checkout/',
				element: <Checkout /> ,
			},
		]
	},
	{
		path: '/:cat/:model/checkout/',
		element: <Checkout /> ,
	},
]