import { useSnapshot } from "valtio"
import Deck from "./Deck"
import Engine from "./Engine"
import Gelcoat from "./Gelcoat"
import "./Panel.scss"
import Tubes from "./Tubes"
import Upholstery from "./Upholstery"
import WeatherProtection from "./WeatherProtection"
import { useNavigate, useParams } from 'react-router-dom';

import { DB } from "../store/store";
import FinishingTouches from "./FinishingTouches"
import { displayShareHandler } from "../helper/helper"
import html2canvas from "html2canvas"
import RescueMode from "./RescueMode"

const Panel = () => {
    const snap = useSnapshot(DB);
    const navigate = useNavigate();
    const params = useParams();
    const { label, cat, model } = params;

    return (
        <div className="panel scrollbar-hidden">
            <div className="panel_scroll">
                {snap.models.map((item) => (
                    <div key={item.id} className="panel_main">
                        <h3>
                            {item.name}
                        </h3>
                        <div className="panel_header">
                            <div>
                                <h5>{item.dimensions}</h5>
                                <p>{item.dimensions_plcholder}</p>
                            </div>
                            <div>
                                <h5>{item.capacity}</h5>
                                <p>{item.capacity_plcholder}</p>
                            </div>
                        </div>
                        <RescueMode item={item}/>
                        <Engine item={item} />
                        <Tubes item={item} />
                        <Upholstery item={item} />
                        <Gelcoat item={item} />
                        <Deck item={item} />
                        <WeatherProtection item={item} />
                        <FinishingTouches item={item} />
                        <div className="finish">
                            <a className="button" 
                                onClick={async () => {
                                    const body = document.body;
                                    body.classList.add('active_code_modal');
                                    // body.classList.remove('dark-mode');
                                    const dwnImg = document.getElementById("model_img");

                                    await html2canvas(dwnImg, {
                                        scale: 3.5,
                                        allowTaint: true,
                                        useCORS: true,
                                        backgroundColor: null,
                                    }).then(function (canvas) {
                                        DB.pdfImg = canvas.toDataURL("image/png");
                                    });

                                    const dwnImg2 = document.getElementById("model_img");

                                    await html2canvas(dwnImg2, {
                                        scale: 1,
                                        allowTaint: true,
                                        useCORS: true,
                                        backgroundColor: null,
                                    }).then(function (canvas2) {
                                        DB.emailImg = canvas2.toDataURL("image/png");
                                    });

                                    await displayShareHandler(params, DB);
                                    body.classList.remove('active_code_modal')

                                    if(label){
                                        return(
                                            navigate(`/${label}/${cat}/${model}/checkout`)
                                        )
                                    }else{
                                        return(
                                            navigate(`/${cat}/${model}/checkout`)
                                        )
                                    }
                                }}
                            >Finish</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Panel