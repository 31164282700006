import React from 'react'
import ColorPicker from './sub_components/ColorPicker'
import ConfirmBox from './sub_components/ConfirmBox'
import Selector from './sub_components/Selector'

const Tubes = ({ item }) => {

    const mainData = item.nodes.filter((f) => f.node_name === "Tubes");
    const colorData = mainData[0].options.filter((f) => f.name === "Tube Colour");
    const logoData = mainData[0].options.filter((f) => f.name === "Would you like to add your own logo to the tubes?");
    const selectorData = mainData[0].options.filter((f) => f.name === "Inflation Options");

    return (
        <> 
            {mainData[0].active &&
                <div className='tubes'>
                    <h4>{mainData[0].node_name}</h4>
                    {colorData[0].active && <ColorPicker
                        colorData={colorData}
                        node = {1}
                        option = {0} 
                    />}
                    {logoData[0].active && <ConfirmBox
                        logoData={logoData}
                        node = {1}
                        option = {1} 
                    />}
                    {selectorData[0].active && <Selector
                        selectorData={selectorData}
                        node = {1}
                        option = {2} 
                    />}
                </div>
            }
        </>
    )
}

export default Tubes