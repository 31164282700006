import { useState } from 'react';
import logo from './assets/images/Header/logo2.png';
import mobile_logo from './assets/images/Header/mobile_logo.svg';
import './Header.scss';
import Menu from './componenets/Menu';
import "./componenets/Menu.scss";
import { DB } from './store/store';

const Header = () => {
    const [isOpen, setIsopen] = useState(false);

    const toggleMenu = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);

        if(isOpen){
            DB.mobileMenuIdx = ''
        }
    }

    const menuBackHandler = () => {
        DB.mobileMenuIdx = ''
    }

    return (
        <>
            <div className={`header ${isOpen ? "show-nav" : ''}`}>
                <div className="max-wrap2 position-relative" style={{zIndex: "1"}}>
                    <div className="compartment">
                        <a className="logo" href={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
                            <img src={logo} alt="logo" />
                        </a>
                        <div className="header-button-section">
                            <a href="/" type="submit" className="btn btn_dark btn_menu_active">Build My Tender</a>
                            <a onClick={toggleMenu} className="btn btn_transparent btn_menu_active_2">{isOpen ? 'Close' : 'Menu'}</a>
                        </div>
                    </div>
                    <div className={`compartment mobile-compartment ${DB.mobileMenuIdx !== '' ? 'showBG' : ''}`}>
                        <a className="mobile-logo" href={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
                            <img src={mobile_logo} alt="logo" />
                        </a>
                        <div className="header-mobile-button-section">
                            <a  onClick={toggleMenu} className="btn btn_transparent btn_menu_active_2">{isOpen ? 'Close' : 'Menu'}</a>
                            {DB.mobileMenuIdx !== '' ? <a className="menu-back position-absolute" onClick={menuBackHandler}>&lt; Back</a> : ''}
                        </div>
                    </div>
                </div>
                {
                    isOpen && <Menu/>
                }
            </div>
        </>
    )
}

export default Header