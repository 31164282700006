import React from 'react'
import ColorPicker from './sub_components/ColorPicker'

const Gelcoat = ({ item }) => {

    const mainData = item.nodes.filter((f) => f.node_name === "Gelcoat");
    const colorData = mainData[0].options.filter((f) => f.name === "Choose your helm colour");
    const colorData_2 = mainData[0].options.filter((f) => f.name === "Choose your tube pod colour");
    const colorData_3 = mainData[0].options.filter((f) => f.name === "Choose your deck colour");
    const colorData_4 = mainData[0].options.filter((f) => f.name === "Choose your hull colour");

    const picker = ["#FFF", "#969BA1", "#737A7D", "#C4C4C4", "#545B5E", "#373C40", "#2E3033", "#000001"]

    return (
        <> 
            {mainData[0].active &&
                <div className='upholstery'>
                    <h4>{mainData[0].node_name}</h4>
                    {colorData[0].active && <ColorPicker
                        colorData = {colorData}
                        className="gelcoat_picker"
                        node = {3}
                        option = {0} 
                    />}
                    {colorData_3[0].active && <ColorPicker
                        colorData = {colorData_3}
                        className="gelcoat_picker"
                        node = {3}
                        option = {2} 
                    />}
                    {colorData_4[0].active && <ColorPicker
                        colorData = {colorData_4}
                        className="gelcoat_picker"
                        node = {3}
                        option = {3} 
                    />}
                    {colorData_2[0].active && <ColorPicker
                        colorData = {colorData_2}
                        className="gelcoat_picker_bottom"
                        node = {3}
                        option = {1} 
                    />}
                </div>
            }
        </>
    )
}

export default Gelcoat