import React from 'react';
import image_icon from "../assets/images/image_icon.svg";
import close_icon from "../assets/images/close_icon.svg";
import ViewPdf from "../ui/pdf/ViewPdf";
import './GenerateCode.scss';
import { DB } from '../store/store';

const Download = ( {onDrop, displayPopUp} ) => {

    const downloadImage = () => {
        const dwnImg = document.getElementById("model_img");

        const link = document.createElement("a");
        link.download = `WJT.png`;
        link.href = DB.pdfImg;
        link.click();
    }

    return (
        <div className={`code_modal ${displayPopUp} download_buttons_block`}>
            <div className='close_icon' onClick={onDrop}>
                <img src={close_icon} alt="Close Icon" width="16px"/>
            </div>

            <div className='download_image_block'>
                <button onClick={downloadImage}><span><img src={image_icon} alt="Image icon" width="16px" /></span> <span>Download Image</span></button>
            </div>
            <div className='download_pdf_block'>  
                <ViewPdf />
            </div>
        </div>
    )
}

export default Download