import { Fragment, useEffect, useState } from "react";
import "./FooterMenu.scss"
import { DB } from "../store/store";
import { useSnapshot } from "valtio";

const FooterMenu = () => {
    const menuList = DB.footerMenu;
    const [value, setValue] = useState();
    const snap = useSnapshot(DB);

    useEffect(() => {
        const temp = [];
        {menuList?.map((a, b) => {
            temp.push({id : b, active : false});
        })}
        setValue(temp);
    }, [menuList]);

    const handleClick = (val) => {
        setValue(val === value ? '' : val);
    }

    return (
        <footer id="colophon" className="mt-auto">
            <div className="max-wrap">
                <div className="footer-form-section">
                    <div className="row">
                        <div className="col-lg-2 col-md-12">
                            <div className="footer-form-logo-sec">
                                <img src="/images/footer/footer_williams_logo.png" alt="logo" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="footer-form-text-sec">
                                <p className="footer-primary-text mb-1">Bringing the joy direct to your inbox</p>
                                <h4>Sign up to our newsletter today</h4>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <form id="newsletterform">
                                <div className="input">
                                    <input type="text" id="email" name="email" placeholder="Email Address" className="input-area" />
                                    <button type="submit" className="btn btn_dark input-button" id="submit">
                                        <img src="/images/footer/icons.png"  alt="button-icon" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <nav className="footer-navigation">
                    <ul className="footer-navigation-wrapper">
                        {menuList?.map((a, b) => (
                            <Fragment key={b}>
                                {
                                    a.menu_item_parent === '0' 
                                    ? 
                                        <li className={`toggle-class ${a.classes[0]} ${b === value ? 'acc-icon' : ''}`} onClick={() => handleClick(b)}>
                                            <a className="top-link"><span>{a.title}</span></a>
                                            <ul className={`sub-menu ${value === b ? 'show' : ''}`}>
                                                {menuList?.map((c, d) =>(
                                                    <Fragment key={d}>
                                                        {'' + a.ID === c.menu_item_parent ?
                                                            <li>
                                                                <a className={`sd-link ${c.classes[0]}`} href={c.url}><span>{c.title}</span></a>
                                                                <ul className={`sub-menu footer-icons ${value === b ? 'show' : ''}`}>
                                                                    {menuList?.map((e, f) =>(
                                                                        <Fragment key={f}>
                                                                            {'' + c.ID === e.menu_item_parent ?
                                                                                <li>
                                                                                    <a className={`sd-link ${e.classes[0]}`} href={e.url}><span>{e.title}</span></a>
                                                                                </li>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </Fragment>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                            :
                                                            <></>
                                                        }
                                                    </Fragment>
                                                ))}
                                            </ul>
                                        </li> 
                                    : 
                                        <></>
                                }
                            </Fragment>
                        ))}
                    </ul>
                </nav>

                <div className="copyright-section">
                    <p className="footer-primary-text"> Copyright &copy; 2023 Williams Jet Tenders Limited, Vogue Business Park, Berinsfield, Oxfordshire OX10 7LN.Registered number: 05227633. Registered in England, UK.</p>
                </div>
            </div>
        </footer>
    )
}

export default FooterMenu